import { Form } from 'connex-cds';
import React from 'react';
import { entities, connections } from '../../../api';
import { DETAIL_TYPES, ListAndDetail } from '../../../for_sdk/list-and-detail/ListAndDetail';
import { PartnerEditor } from '../PartnerEditor';
import config from '../PartnerConnectionConfig';

const onDelete = connectionRef => connections.deleteConnection({ connectionRef });

const onCreate = val => {
  console.log('create partner', val);
  return entities.createEntityPartnerConnection({ entityRef: val?.fromRef, partnerRef: val?.toRef });
};

const onUpdate = val => console.log('onUpdate', val);

export const Partners = () => {
  const { values } = Form.useFormContext();

  const initialFetch = React.useMemo(() => {
    return { fn: entities.listConnections, args: { entityRef: values?.crn, type: 'entity-partner' } };
  }, [values?.crn]);

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.DRAWER}
      Editor={PartnerEditor}
      config={config}
      initialFetch={initialFetch}
      context={values}
      updateFn={onUpdate}
      createFn={onCreate}
      deleteFn={onDelete}
    />
  );
};
