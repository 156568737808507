import React from 'react';
import { TemplateTab } from './TemplateTab';

const templateTabConfig = {
  tabs: [
    {
      stringId: 'udmData',
      testId: 'udmData',
      component: <TemplateTab templateName="udmData" />,
    },
  ],
  translate: [{ getPath: 'stringId', setPath: 'name' }],
};

export default templateTabConfig;
