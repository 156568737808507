import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { DrawerProvider } from '../../components/drawer';
import { Translate } from '../../components/localization';
import { MaterialIcon } from '../../components/icons';
import { AppSwitcher } from './app-switcher/AppSwitcher';
import { Profile } from './profile/Profile';
import CaiEntitySwitcher from './cai-entity-switcher/CaiEntitySwitcher';

import style from './style';

const Styled = styled.header`
  ${style}
`;

export const Header = ({
  className,
  appContext,
  entityContext,
  userContext,
  onChange,
  onProfileClick,
  disableAppTray,
  disableEntityPicker,
}) => {
  const { isEntityPickerRequired, appEntities, currentFeature, headerText } = appContext;
  const { entityRef, setEntityRef } = entityContext;
  const { userApps, userAppsIsLoading, user } = userContext;

  const handleEntityChange = React.useCallback(
    (val, companyName) => {
      setEntityRef(val);
      if (user?.profileRef) {
        window?.FS?.identify?.(user?.profileRef, { companyName });
      }
    },
    [setEntityRef, user?.profileRef]
  );

  return (
    <Styled>
      <DrawerProvider>
        <div className={cn('header', className)}>
          <div className={cn('left', { visible: !!currentFeature?.id })}>
            <div className={cn('feature-name')}>
              {currentFeature?.iconName ? (
                <MaterialIcon iconName={currentFeature?.iconName} className="feature-icon" />
              ) : null}
              {headerText || (currentFeature ? <Translate stringId={currentFeature.labelStringId} /> : '')}
            </div>
            {!disableEntityPicker && isEntityPickerRequired ? (
              <>
                <CaiEntitySwitcher value={entityRef} onChange={handleEntityChange} entities={appEntities} />
              </>
            ) : null}
          </div>
          <div className="right">
            <div className="center-aligned">
              {!disableAppTray && (
                <AppSwitcher
                  onChange={onChange}
                  userApps={userApps}
                  isLoading={userAppsIsLoading}
                  appContext={appContext}
                />
              )}
              <Profile onClick={onProfileClick} user={user} />
            </div>
          </div>
        </div>
      </DrawerProvider>
    </Styled>
  );
};
