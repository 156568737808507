import { Drawer, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import cn from 'classnames';
import { DETAIL_TYPES } from '../../for_sdk/list-and-detail/ListAndDetail';
import { useListAndDetailContext } from '../../for_sdk/list-and-detail/ListAndDetailProvider';
import tabsConfig from './tabs/config';
import style from './style';

const Styled = styled.div`
  ${style}
`;
export const ProfileEditor = ({ updateFn, createFn, deleteFn }) => {
  const { entityRef } = useParams();
  const { values, mode } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { detailType } = useListAndDetailContext();
  const navigate = useNavigate();

  console.log('values', values);

  const onFinally = React.useCallback(() => {
    const behaviors = {
      [DETAIL_TYPES.VIEW]: () => navigate(`/profiles`), // TODO: Switch to edit mode and stay put.
      [DETAIL_TYPES.DRAWER]: closeDrawer,
      [DETAIL_TYPES.FULL_WIDTH_DRAWER]: closeDrawer,
      [DETAIL_TYPES.MODAL]: () => {},
    };
    behaviors[detailType]?.();
  }, [closeDrawer, detailType, navigate]);

  const handleCreate = React.useCallback(
    entity =>
      createFn?.(entity)?.then(response => {
        onFinally?.();
        return response;
      }),
    [createFn, onFinally]
  );

  const handleUpdate = React.useCallback(
    entity =>
      updateFn?.(entity)?.then(response => {
        onFinally?.();
        return response;
      }),
    [onFinally, updateFn]
  );

  return (
    <Styled className={cn('company-editor')}>
      <Typography.H2>
        {mode === 'edit' ? (
          `${[values?.lastName, values?.firstName].filter(x => !!x).join(', ')}`
        ) : (
          <Localization.Translate stringId="createProfile" data-testid="create-profile-header" />
        )}
      </Typography.H2>
      <hr />
      {/*Props, other than config, are passed to each underlying tab component.*/}
      <Form.Tabs
        config={tabsConfig}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onFinally={onFinally}
      />
    </Styled>
  );
};
