import { Drawer, Modal } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router';
import { useListAndDetailContext } from './ListAndDetailProvider';

export const useClickHandlers = () => {
  const { config, Editor, detailType, detailOptions, onPick, updateFn, createFn, deleteFn } = useListAndDetailContext();

  const { closeDrawer, openDrawer } = Drawer.useDrawerContext();
  const { closeModal, openModal } = Modal.useModalContext();

  const EditorComponent = React.useMemo(
    () => <Editor updateFn={updateFn} createFn={createFn} deleteFn={deleteFn} />,
    [createFn, deleteFn, updateFn]
  );

  const navigate = useNavigate();
  const handlers = React.useMemo(() => {
    return {
      drawer_add: () => {
        console.log('drawer add');
        openDrawer?.({
          titleStringId: `${config.name || config.path}Editor`,
          component: React.cloneElement(EditorComponent, { onFinally: closeDrawer }),
          ...detailOptions,
        });
      },
      drawer_edit: () => {
        console.log('drawer_edit');
        openDrawer?.({
          titleStringId: `${config.name || config.path}Editor`,
          component: React.cloneElement(EditorComponent, { onFinally: closeDrawer }),
          ...detailOptions,
        });
      },
      full_width_drawer_add: ({ title }) => {
        console.log('full_width_drawer_add');
        openDrawer?.({
          titleStringId: title || `${config.name || config.path}Editor`,
          width: '100%',
          component: React.cloneElement(EditorComponent, { onFinally: closeDrawer }),
          ...detailOptions,
        });
      },
      full_width_drawer_edit: ({ title }) => {
        openDrawer?.({
          titleStringId: title || `${config.name || config.path}Editor`,
          width: '100%',
          component: React.cloneElement(EditorComponent, { onFinally: closeDrawer }),
          ...detailOptions,
        });
      },

      view_add: () => {
        console.log('view add');
        navigate('detail');
      },
      view_edit: () => {
        console.log('view edit');
        navigate('detail');
      },
      modal_add: () => {
        openModal?.({
          titleStringId: `${config.name || config.path}Editor`,
          component: React.cloneElement(EditorComponent, { onFinally: closeModal }),
          ...detailOptions,
        });
      },
      modal_edit: () => {
        openModal?.({
          titleStringId: `${config.name || config.path}Editor`,
          component: React.cloneElement(EditorComponent, { onFinally: closeModal }),
          ...detailOptions,
        });
      },
    };
  }, [
    EditorComponent,
    closeDrawer,
    closeModal,
    config.name,
    config.path,
    detailOptions,
    navigate,
    openDrawer,
    openModal,
  ]);

  return {
    handleAddClick: createFn ? handlers[`${detailType}_add`] : undefined,
    handleRowClick: onPick || handlers[`${detailType}_edit`],
  };
};
