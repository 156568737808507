import React, { useContext } from 'react';
import { useListConfig } from '../list-util/useListConfig';

const ListAndDetail = React.createContext();

export const useListAndDetailContext = () => useContext(ListAndDetail);

const ListAndDetailProvider = ({ children, initialFetch, config, ...props }) => {
  const { rows, busy, formConfig } = useListConfig({ initialFetch, config });

  const parentContext = useListAndDetailContext();

  return (
    <ListAndDetail.Provider value={{ rows, busy, formConfig, config, parentContext, ...props }}>
      {children}
    </ListAndDetail.Provider>
  );
};

export default ListAndDetailProvider;
