import { Form } from 'connex-cds';

export default {
  path: 'profiles',
  labelStringId: 'profiles',
  testId: 'profiles',
  keyPath: 'crn',
  fields: [
    { path: 'lastName', labelStringId: 'family_name', testId: 'family_name', dataType: Form.FIELD_TYPES.string },
    { path: 'firstName', labelStringId: 'given_name', testId: 'given_name', dataType: Form.FIELD_TYPES.string },
    { path: 'email', labelStringId: 'email', testId: 'email', dataType: Form.FIELD_TYPES.string },
    { path: 'toRef', labelStringId: 'toRef', testId: 'toRef', dataType: Form.FIELD_TYPES.string, hidden: true },
    { path: 'fromRef', labelStringId: 'fromRef', testId: 'fromRef', dataType: Form.FIELD_TYPES.string, hidden: true },
  ],
};
