import { Form } from 'connex-cds';

export default {
  path: 'profiles',
  labelStringId: 'profiles',
  testId: 'profiles',
  keyPath: 'crn',
  fields: [
    { path: 'lastName', labelStringId: 'family_name', testId: 'family_name', dataType: Form.FIELD_TYPES.string },
    { path: 'firstName', labelStringId: 'given_name', testId: 'given_name', dataType: Form.FIELD_TYPES.string },
    { path: 'email', labelStringId: 'email', testId: 'email', dataType: Form.FIELD_TYPES.string },
    { path: 'phoneNumber', labelStringId: 'phoneNumber', testId: 'phoneNumber', dataType: Form.FIELD_TYPES.string },
    {
      path: 'password',
      labelStringId: 'password',
      testId: 'password',
      dataType: Form.FIELD_TYPES.string,
      password: true,
      allowedModes: Form.MODES.CREATE,
      hidden: true,
    },
  ],
};
