import cn from 'classnames';
import { Core, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useTemplateContext } from './context/Provider';
import templateTabConfig from './template-tab-config';
import { useTemplatesSetup, useUpdateSetupItem, useCreateSetupItem } from '../../api/query-hooks/masterData';

const { Tabs } = Core;

const Styled = styled(Layout.Column)`
  .actions {
    display: flex;
    justify-content: flex-end;
  }
`;

export const View = () => {
  const templatesSetup = useTemplatesSetup();
  const { state } = useTemplateContext();

  const [busy, setBusy] = React.useState(false);
  const update = useUpdateSetupItem();
  const create = useCreateSetupItem();

  const handleSave = React.useCallback(() => create(state), [create, state]);
  const handleEdit = React.useCallback(() => update(state), [update, state]);

  const handleSaveClick = React.useCallback(() => {
    setBusy(true);
    const promise = templatesSetup?.data?.crn ? handleEdit : handleSave;
    promise().finally(() => {
      setBusy(false);
    });
  }, [handleEdit, handleSave, templatesSetup?.data?.crn]);

  const templatesConfig = React.useMemo(() => {
    const tabs = templateTabConfig?.tabs ?? [];
    return { ...templateTabConfig, tabs: tabs?.map(tab => ({ ...tab, disabled: busy })) };
  }, [templateTabConfig, busy]);

  return (
    <Styled className={cn('view')}>
      <Tabs config={templatesConfig} />
      <div className="actions" style={{ padding: '15px' }}>
        <Core.Button
          loading={busy}
          type="primary"
          data-testid="save-button"
          stringId="save"
          onClick={handleSaveClick}
        />
      </div>
    </Styled>
  );
};
