import { Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Edit } from './Edit';
import { View } from './View';

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .permissions-list {
    &.view {
      border: 1px solid var(--color-border-input);
      border-radius: var(--radius-container);
      padding: 5px 10px;
      min-height: 75px;
    }
    margin-bottom: 20px;
  }
`;

export const PermissionsFieldEditor = ({ onChange, ...props }) => {
  const { values } = Form.useFormContext();
  const [isEdit, setIsEdit] = React.useState(false);
  const viewRef = React.useRef();

  const toggleMode = React.useCallback(() => {
    setIsEdit(s => {
      if (s) {
        setTimeout(() => viewRef?.current?.blur?.());
      }
      return !s;
    });
  }, []);

  const handleChange = React.useCallback(
    val => {
      const permissionsObject = val
        ?.split?.(',')
        .map(v => v.trim())
        .filter(v => !!v)
        .reduce((acc, value) => {
          acc.push({ id: value });
          return acc;
        }, []);
      onChange([{ groupId: '', permissions: permissionsObject }]);
      toggleMode();
      viewRef?.current?.blur?.();
    },
    [onChange, toggleMode]
  );

  return (
    <Styled className={cn('permissions-editor')}>
      <Typography.Subtitle>
        <Localization.Translate stringId="permissions" data-testid="permissions-label" />
      </Typography.Subtitle>
      {isEdit ? (
        <Edit initialValue={values} onChange={handleChange} />
      ) : (
        <View onClick={toggleMode} value={values} ref={viewRef} />
      )}
    </Styled>
  );
};
