import cn from 'classnames';
import { Form, Localization, Typography, Drawer } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import style from './style';
import tabsConfig from './tabs/config';

const Styled = styled.div`
  ${style}
`;

const { DETAIL_TYPES } = Form;

export const AppsEditor = ({ updateQuery, createQuery, context }) => {
  const { values, mode } = Form.useFormContext();
  const { detailType } = context;
  const { closeDrawer } = Drawer.useDrawerContext();
  const navigate = useNavigate();

  const onFinally = React.useCallback(() => {
    const behaviors = {
      [DETAIL_TYPES.VIEW]: () => navigate(`..`), // TODO: Switch to edit mode and stay put.
      [DETAIL_TYPES.DRAWER]: closeDrawer,
      [DETAIL_TYPES.FULL_WIDTH_DRAWER]: closeDrawer,
      [DETAIL_TYPES.MODAL]: () => {},
    };
    behaviors[detailType]?.();
  }, [closeDrawer, detailType, navigate]);

  return (
    <Styled className={cn('apps-editor')}>
      <Typography.H2>
        {mode === 'edit' ? (
          values?.name
        ) : (
          <Localization.Translate stringId="createEntity" data-testid="create-company-header" />
        )}
      </Typography.H2>
      <hr />
      {/*Props, other than config, are passed to each underlying tab component.*/}
      <Form.Tabs config={tabsConfig} createQuery={createQuery} updateQuery={updateQuery} onFinally={onFinally} />
    </Styled>
  );
};
