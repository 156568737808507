import { Core, Form } from 'connex-cds';
import React from 'react';
import ListAndDetailProvider, { useListAndDetailContext } from './ListAndDetailProvider';
import { View } from './View';
import { DefaultEditor } from './DefaultEditor';

export const DETAIL_TYPES = {
  DRAWER: 'drawer',
  VIEW: 'view',
  FULL_WIDTH_DRAWER: 'full_width_drawer',
  MODAL: 'modal',
};

const ListAndDetailForm = () => {
  const { config, rows, busy, formConfig, id } = useListAndDetailContext();

  return (
    <Core.Spinner spin={busy || busy === null}>
      <Form.FormProvider config={formConfig} initialValues={{ [config.name || config.path || 'rows']: rows }}>
        <View />
      </Form.FormProvider>
    </Core.Spinner>
  );
};

export const ListAndDetail = ({
  config,
  initialFetch,
  Editor = DefaultEditor,
  detailType,
  detailOptions,
  updateFn,
  createFn,
  deleteFn,
  pickerMode,
  context,
  onPick,
}) => {
  return (
    <ListAndDetailProvider
      config={config}
      initialFetch={initialFetch}
      Editor={Editor}
      detailType={detailType}
      detailOptions={detailOptions}
      createFn={createFn}
      updateFn={updateFn}
      deleteFn={deleteFn}
      pickerMode={pickerMode}
      context={context}
      onPick={onPick}
    >
      <ListAndDetailForm />
    </ListAndDetailProvider>
  );
};
