import cn from 'classnames';
import { Core, Form, Layout, Localization } from 'connex-cds';
import { find, values } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { apps } from '../../api';
import { useListAndDetailContext } from '../../for_sdk/list-and-detail/ListAndDetailProvider';
import style from './style';

const Styled = styled(Layout.Column)`
  ${style}
`;

const optionsMap = r => {
  return { id: r.crn, label: r.name };
};

const getInterimOptions = currentFormValue => {
  if (!values(currentFormValue)?.join('')?.trim?.()?.length) return [];

  return [
    {
      name: currentFormValue?.name,
      createDate: currentFormValue?.createDate,
      modifyDate: currentFormValue?.modifyDate,
      crn: currentFormValue?.toRef,
    },
  ];
};

export const ProfileConnectionEditor = ({ updateFn, createFn, deleteFn, onFinally }) => {
  const { context, rows } = useListAndDetailContext();
  const { Components, setFieldValue, ...formContext } = Form.useFormContext();
  const [allApps, setAllApps] = React.useState(null);

  const [options, setOptions] = React.useState(getInterimOptions(formContext?.values));

  const [busy, setBusy] = React.useState(false);

  const _options = React.useMemo(async () => {
    const allApps = await apps.listApps();

    setAllApps(allApps);

    const assignableAppRefs = allApps.filter(app => app.isAssignableToProfile).map(app => app.crn);

    const alreadyConnectedRefs = rows.map(app => app.toRef);

    return allApps
      .filter(app => !alreadyConnectedRefs.includes(app.crn))
      .filter(app => assignableAppRefs.includes(app.crn));
  }, [rows]);

  const value = React.useMemo(() => {
    return formContext?.values?.toRef || undefined;
  }, [formContext?.values?.toRef]);

  const ensureOptionsContainCurrent = React.useCallback(
    (options = []) => {
      if (value) {
        const targetAppRef = formContext?.values?.toRef;
        const targetApp = find(allApps, { crn: targetAppRef });

        if (!targetApp) {
          return options || [];
        }
        const isValueAlreadyInList = !!find(options, { crn: targetAppRef });
        return isValueAlreadyInList ? options : options.concat(targetApp);
      }

      return options;
    },
    [allApps, formContext?.values?.toRef, value]
  );

  React.useEffect(() => {
    if (_options instanceof Promise) {
      setBusy(true);
      _options
        .then(response => {
          setOptions(ensureOptionsContainCurrent(response));
        })
        .finally(() => {
          setBusy(false);
        });
    } else {
      setOptions(ensureOptionsContainCurrent(_options || []) || []);
    }
  }, [_options, ensureOptionsContainCurrent]);

  const handleChange = React.useCallback(
    appRef => {
      const targetApp = find(allApps, { crn: appRef });
      setFieldValue('name', targetApp?.name);
      setFieldValue('fromRef', context?.crn);
      setFieldValue('toRef', targetApp.crn);
    },
    [allApps, context?.crn, setFieldValue]
  );

  const transformedOptions = React.useMemo(() => {
    if (options?.length) {
      return options.map(optionsMap);
    }
    return options || [];
  }, [options]);

  const handleCreate = React.useCallback(
    connection =>
      createFn?.(connection)?.then(response => {
        onFinally?.();
        return response;
      }),
    [createFn, onFinally]
  );

  const handleUpdate = React.useCallback(
    connection =>
      updateFn?.(connection)?.then(response => {
        onFinally?.();
        return response;
      }),
    [onFinally, updateFn]
  );

  const handleDelete = React.useCallback(
    connectionRef =>
      deleteFn?.(connectionRef)?.then(response => {
        onFinally?.();
        return response;
      }),
    [deleteFn, onFinally]
  );

  return (
    <Styled className={cn('connection-editor')}>
      {transformedOptions?.length ? (
        <>
          <Core.Select
            name="connection"
            id="connection"
            onChange={handleChange}
            value={value}
            options={transformedOptions || []}
            testId="connection-form-field"
          />
          <div className="buttons">
            <div className="left">
              <Components.DeleteButton onDelete={handleDelete} />
            </div>
            <div className="right">
              <Components.SaveButton onCreate={handleCreate} onUpdate={handleUpdate} />
            </div>
          </div>
        </>
      ) : (
        <Localization.Translate stringId="noAppsAvailable" data-testid="noAppsAvailableMessage" />
      )}
    </Styled>
  );
};
