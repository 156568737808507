import cn from 'classnames';
import { Drawer, Form, Localization, Typography } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import style from './style';
import tabsConfig from './tabs/config';

const Styled = styled.div`
  ${style}
`;

export const CompanyEditor = ({ updateQuery, createQuery, deleteQuery, context }) => {
  const { values, mode } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { detailType } = context;
  const navigate = useNavigate();

  const {DETAIL_TYPES} = Form;

  const onFinally = React.useCallback(() => {
    const behaviors = {
      [DETAIL_TYPES.VIEW]: () => navigate(`/companies`), // TODO: Switch to edit mode and stay put.
      [DETAIL_TYPES.DRAWER]: closeDrawer,
      [DETAIL_TYPES.FULL_WIDTH_DRAWER]: closeDrawer,
      [DETAIL_TYPES.MODAL]: () => {},
    };
    behaviors[detailType]?.();
  }, [closeDrawer, detailType, navigate]);

  return (
    <Styled className={cn('company-editor')}>
      <Typography.H2>
        {mode === 'edit' ? (
          values?.name
        ) : (
          <Localization.Translate stringId="createEntity" data-testid="create-company-header" />
        )}
      </Typography.H2>
      <hr />
      {/*Props, other than config, are passed to each underlying tab component.*/}
      <Form.Tabs
        config={tabsConfig}
        createQuery={createQuery}
        updateQuery={updateQuery}
        onFinally={onFinally}
      />
    </Styled>
  );
};
