import { Form } from 'connex-cds';
import { General } from './General';
import { Users } from './Users';
import { Partners } from './Partners';

export default {
  tabs: [
    {
      stringId: 'general',
      testId: 'general-tab',
      component: <General />,
    },
    {
      stringId: 'users',
      testId: 'users-tab',
      component: <Users />,
      allowedModes: Form.MODES.EDIT,
    },
    {
      stringId: 'partners',
      testId: 'partners-tab',
      component: <Partners />,
      allowedModes: Form.MODES.EDIT,
    },
  ],
  translate: [{ getPath: 'stringId', setPath: 'name' }],
};
