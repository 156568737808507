import { Form } from 'connex-cds';
import { find } from 'lodash';
import { FILTER_STRATEGY } from '../../../../for_sdk/filter-strategies';

const appTypes = [{ id: 'email', label: 'Email' }];

export default {
  path: 'templates',
  labelStringId: 'templates',
  testId: 'templates',
  keyPath: 'crn',
  fields: [
    {
      path: 'crn',
      labelStringId: 'crn',
      testId: 'crn',
      dataType: Form.FIELD_TYPES.string,
      allowedModes: Form.MODES.CREATE,
    },
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: Form.FIELD_TYPES.string },
    {
      path: 'type',
      labelStringId: 'type',
      testId: 'type',
      dataType: Form.FIELD_TYPES.string,
      listValues: appTypes,
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'email',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      sortKey: 'type',
      formatter: props => {
        const target = find(appTypes, { id: props.row.type });
        return target?.label || '';
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {appTypes.map(type => (
              <option value={type.id}>{type.label}</option>
            ))}
          </select>
        );
      },
    },
    {
      path: 'details.subject',
      name: 'subject',
      labelStringId: 'subject',
      testId: 'subject',
      dataType: Form.FIELD_TYPES.string,
    },
    {
      path: 'details.htmlMessage',
      name: 'htmlMessage',
      labelStringId: 'htmlMessage',
      testId: 'htmlMessage',
      dataType: Form.FIELD_TYPES.string,
      multiline: true,
      hidden: true,
    },
    {
      path: 'details.textMessage',
      name: 'textMessage',
      labelStringId: 'textMessage',
      testId: 'textMessage',
      dataType: Form.FIELD_TYPES.string,
      hidden: true,
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: Form.FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
};
