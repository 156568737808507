import { useDefaultRoute } from 'connex-cds';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { Companies } from './views/companies/Companies';
import { Landing } from './views/Landing';
import { TemplatesEditor } from './views/templates/TemplatesEditor';

export const AppRoutes = () => {
  const defaultRoute = useDefaultRoute('landing', false);

  return (
    <Routes>
      <Route path="*" element={<AuthenticatedRoutes />}>
        <Route path="landing" element={<Landing />} />
        <Route path="companies/*" element={<Companies />} />
        <Route path="templates/*" element={<TemplatesEditor />} />
        <Route path="*" element={<Navigate to={defaultRoute} />} />
      </Route>
    </Routes>
  );
};
