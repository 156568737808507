import cn from 'classnames';
import { Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import { useListAndDetailContext } from './ListAndDetailProvider';

const Styled = styled(Layout.Column)``;

export const Detail = ({ onFinally }) => {
  const { Editor, updateFn, createFn, deleteFn } = useListAndDetailContext();

  if (!updateFn || !createFn) {
    console.error('updateFn and createFn are required.');
  }

  return (
    <Styled className={cn('detail')}>
      <Editor updateFn={updateFn} createFn={createFn} deleteFn={deleteFn} onFinally={onFinally} />
    </Styled>
  );
};
