import cn from 'classnames';
import { Drawer, Layout } from 'connex-cds';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useTemplateContext } from './context/Provider';

const { Column, Row } = Layout;
const Styled = styled(Column)`
  textarea {
    width: 100%;
    height: 100%;
    font-family: monospace;
    padding: 5px 10px;
  }
`;

export const TemplateText = ({ onChange, templateName, language }) => {
  const { state } = useTemplateContext();

  const value = React.useMemo(() => {
    return get(state, [templateName, language], '');
  }, [state, templateName, language]);

  return (
    <Drawer.DrawerProvider>
      <Styled className={cn('template-text')}>
        <Row style={{ minHeight: '28px', marginLeft: 'auto' }}></Row>
        <textarea value={value} onChange={onChange} />
      </Styled>
    </Drawer.DrawerProvider>
  );
};
