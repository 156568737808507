import { Form } from 'connex-cds';

export default {
  path: 'partners',
  labelStringId: 'partners',
  testId: 'partners',
  keyPath: 'crn',
  fields: [
    { path: 'name', name: 'company', labelStringId: 'company', testId: 'company', dataType: Form.FIELD_TYPES.string },
    { path: 'toRef', labelStringId: 'toRef', testId: 'toRef', dataType: Form.FIELD_TYPES.string, hidden: true },
    { path: 'fromRef', labelStringId: 'fromRef', testId: 'fromRef', dataType: Form.FIELD_TYPES.string, hidden: true },
  ],
};
