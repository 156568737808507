import { css } from 'styled-components';

export default css`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  .ant-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    .ant-tabs-content-holder {
      display: flex;
      flex-direction: column;
      flex: 1;
      .ant-tabs-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        .ant-tabs-tabpane {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }
    }
  }
  .buttons {
    display: flex;
    .left {
      flex: 1;
    }
    .right {
      display: flex;
      gap: 10px;
    }
  }
`;
