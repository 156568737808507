import React from 'react';
import { ReactComponent as MixComponentsIcon } from '../../assets/mix-components-icon.svg';
import { ReactComponent as DriversIcon } from '../../assets/drivers-icon.svg';
import { ReactComponent as VehicleTypesIcon } from '../../assets/vehicle-types-icon.svg';
import SvgIcon from '@mui/material/SvgIcon';

const CustomIcons = {
  MixComponentsIcon: props => <SvgIcon inheritViewBox component={MixComponentsIcon} {...props} />,
  DriversIcon: props => <SvgIcon inheritViewBox component={DriversIcon} {...props} />,
  VehicleTypesIcon: props => <SvgIcon inheritViewBox component={VehicleTypesIcon} {...props} />,
};

export default CustomIcons;
