import { Form } from 'connex-cds';
import { ProfileApps } from './ProfileApps';
import { General } from './General';

export default {
  tabs: [
    {
      stringId: 'general',
      testId: 'general-tab',
      component: <General />,
    },
    {
      stringId: 'apps',
      testId: 'apps-tab',
      component: <ProfileApps />,
      allowedModes: Form.MODES.EDIT,
    },
  ],
  translate: [{ getPath: 'stringId', setPath: 'name' }],
};
