import cn from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const Styled = styled.div``;
export const Landing = props => {
  const navigate = useNavigate();

  const handleClick = React.useCallback(
    id => {
      navigate(id);
    },
    [navigate]
  );

  return (
    <Styled className={cn('landing')}>
      Landing
      <div onClick={() => handleClick('services')} style={{ cursor: 'pointer' }}>
        Services
      </div>
      <div onClick={() => handleClick('test/subitem1')} style={{ cursor: 'pointer' }}>
        test/subitem1
      </div>
    </Styled>
  );
};
