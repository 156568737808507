import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { masterData as masterDataApi, masterData } from '../../api';

export const useTemplatesSetup = () => {
  const entityRef = 'command-alkon-inc';

  const query = useQuery({
    queryKey: ['setup', entityRef, 'templates-pa'],
    queryFn: () => masterData.getMasterData({ entityRef, typeId: 'templates-pa' }),
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query?.data?.[0],
  };
};

export const useUpdateSetupItem = () => {
  const typeId = 'templates-pa';
  const entityRef = 'command-alkon-inc';
  const queryClient = useQueryClient();

  const queryKey = ['setup', entityRef, typeId];

  const mutation = useMutation({
    mutationFn: item => masterDataApi.updateMasterData({ entityRef, typeId, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useCreateSetupItem = () => {
  const typeId = 'templates-pa';
  const queryClient = useQueryClient();
  const entityRef = 'command-alkon-inc';

  const queryKey = ['setup', entityRef, typeId];

  const mutation = useMutation({
    mutationFn: item => masterData.createMasterData({ entityRef, typeId, item }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};
