import { Form } from 'connex-cds';
import { FILTER_STRATEGY } from '../../for_sdk/filter-strategies';

export default {
  path: 'entities',
  labelStringId: 'entities',
  testId: 'entities',
  keyPath: 'crn',
  fields: [
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: Form.FIELD_TYPES.string },
    {
      path: 'crn',
      labelStringId: 'crn',
      testId: 'crn',
      dataType: Form.FIELD_TYPES.string,
      hidden: true,
      allowedModes: Form.MODES.CREATE,
      rowKey: true,
    },
    { path: 'timeZone', labelStringId: 'timeZone', testId: 'timeZone', dataType: Form.FIELD_TYPES.timeZone },
    { path: 'modifyDate', labelStringId: 'modifyDate', testId: 'modifyDate', dataType: Form.FIELD_TYPES.string },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: Form.FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
    {
      path: 'isTestEntity',
      labelStringId: 'isTestEntity',
      testId: 'isTestEntity',
      dataType: Form.FIELD_TYPES.boolean,
      formatter: props => (props.row.isTestEntity ? 'Yes' : 'No'),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
  ],
};
