import { http } from 'connex-cds';

export default {
  listProfiles: () => http.get({ apiName: 'support', path: `/profiles?activeOnly=false` }),
  updateProfile: ({ profileRef, profile }) =>
    http.patch({ apiName: 'authnz', path: `/profiles/${profileRef}`, data: profile }),
  deleteProfile: ({ profileRef }) => http.delete({ apiName: 'authnz', path: `/profiles/${profileRef}` }),
  getProfile: ({ profileRef }) => http.get({ apiName: 'authnz', path: `/profiles/${profileRef}` }),
  createProfile: ({ profile }) => http.post({ apiName: 'authnz', path: `/profiles`, data: profile }),
  listConnections: ({ profileRef, type }) =>
    http.get({
      apiName: 'support',
      path: `/profiles/${profileRef}/connexions`,
      queryParams: type ? { type } : undefined,
    }),
};
