import { Form } from 'connex-cds';
import React from 'react';
import { templates } from '../../../../api/query-hooks';
import config from './config';

const { DETAIL_TYPES, ListAndDetail } = Form;

export const Templates = () => {
  const { values } = Form.useFormContext();
  const listQuery = templates.useListTemplates(values?.crn);
  const createQuery = templates.useCreateTemplate(values?.crn);
  const updateQuery = templates.useUpdateTemplate(values?.crn);

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.VIEW}
      config={config}
      listQuery={listQuery}
      createQuery={createQuery}
      updateQuery={updateQuery}
    />
  );
};
