import cn from 'classnames';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  cursor: pointer;
`;

export const View = forwardRef(({ onClick, value }, ref) => {
  const permissionsString = React.useMemo(() => {
    if (Array.isArray(value?.permissions)) {
      // Check for permission groups
      if (value?.permissions?.[0]?.permissions?.length) {
        //Here we can expect the new array-of-permission-groups format.
        return value.permissions.map(group => group.permissions.map(p => p.id).join(', ')).join(', ');
      }
      // Here, we can expect the older, array-of-permission-objects format.
      return value?.permissions.map(p => p.id).join(', ');
    }

    // Here, we can expect the very old object format.
    return Object.keys(value?.permissions || {}).join(', ');
  }, [value.permissions]);

  return (
    <Styled className={cn('view')}>
      <div className="permissions-list view" onClick={onClick} ref={ref} tabIndex={1}>
        {permissionsString}
      </div>
    </Styled>
  );
});
