import cn from 'classnames';
import { Core } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  &&& {
    textarea {
      line-height: 1.5715;
      background-color: var(--color-background-input);
      color: var(--color-text-input);
      border-color: var(--color-border-input);
    }
  }
`;

const prepareInitialValue = initialValue => {
  return Array.isArray(initialValue?.permissions)
    ? initialValue?.permissions.map(p => p.id).join(', ')
    : Object.keys(initialValue?.permissions || {}).join(', ');
};

export const Edit = ({ onChange, initialValue }) => {
  const [permissions, setPermissions] = React.useState(prepareInitialValue(initialValue));
  const ref = React.useRef();

  React.useEffect(() => {
    ref?.current?.focus();
    ref.current.selectionStart = ref?.current?.value?.length || 0;
    ref.current.selectionEnd = ref?.current?.value?.length || 0;
  }, []);

  const handleBlur = React.useCallback(() => {
    onChange(permissions);
  }, [onChange, permissions]);

  const handleEnterEscapeKeys = React.useCallback(e => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      ref?.current?.blur?.();
    }
  }, []);

  const handleChange = React.useCallback(e => {
    setPermissions(e.target.value);
  }, []);

  return (
    <Styled className={cn('permissions-list edit')}>
      <textarea
        onKeyDownCapture={handleEnterEscapeKeys}
        ref={ref}
        value={permissions}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ width: '100%', height: '100px' }}
      />
    </Styled>
  );
};
