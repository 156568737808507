import cn from 'classnames';
import { Drawer, Form, Layout, Modal } from 'connex-cds';
import { camelCase, toUpper } from 'lodash';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { Detail } from './Detail';
import { List } from './List';
import { useListAndDetailContext } from './ListAndDetailProvider';

const { DrawerProvider } = Drawer;

const Styled = styled(Layout.Column)`
  padding: 5px;
  gap: 0;
  .ant-drawer-body {
    display: flex;
  }
`;

// TODO get formConfig from formContext.
const pascalCase = str => camelCase(str).replace(/^(.)/, toUpper);

export const View = () => {
  const { config } = useListAndDetailContext();
  const { Components } = Form.useFormContext();

  const FormComponent = React.useMemo(() => {
    const name = config.name || config.path;
    return Components[pascalCase(name)];
  }, [Components, config.name, config.path]);

  return (
    <Styled className={cn('services')}>
      <FormComponent>
        <Modal.ModalProvider>
          <DrawerProvider>
            <Routes>
              <Route path="" element={<List />} />
              <Route path="detail" element={<Detail />} />
            </Routes>
          </DrawerProvider>
        </Modal.ModalProvider>
      </FormComponent>
    </Styled>
  );
};
