import { http } from 'connex-cds';

export default {
  listEntities: () => http.get({ apiName: 'support', path: `/entities?activeOnly=false`, useLoginToken: true }),
  getEntity: ({ entityRef }) => http.get({ apiName: 'support', path: `/entities/${entityRef}`, useLoginToken: true }),
  createEntity: entity => http.post({ apiName: 'support', path: `/entities`, data: entity, useLoginToken: true }),
  deleteEntity: ({ entityRef }) =>
    http.delete({ apiName: 'support', path: `/entities/${entityRef}`, useLoginToken: true }),
  createEntityProfileConnection: ({ entityRef, profileRef }) =>
    http.post({
      apiName: 'support',
      path: `/entities/${entityRef}/connexions/profiles/${profileRef}`,
      useLoginToken: true,
    }),
  createEntityPartnerConnection: ({ entityRef, partnerRef }) =>
    http.post({
      apiName: 'support',
      path: `/entities/${entityRef}/connexions/partners/${partnerRef}`,
      useLoginToken: true,
    }),
  updateEntity: entity =>
    http.patch({ apiName: 'support', path: `/entities/${entity.crn}`, data: entity, useLoginToken: true }),
  // deleteConnection: ({ connectionRef }) => http.delete({ apiName: 'support', path: `/connexions/${connectionRef}`, useLoginToken: true }),
  listConnections: ({ entityRef, type }) =>
    http.get({
      apiName: 'support',
      path: `/entities/${entityRef}/connexions`,
      queryParams: type ? { type } : undefined,
      useLoginToken: true,
    }),
};
