import cn from 'classnames';
import { Core, Layout } from 'connex-cds';
import React from 'react';
import styled from 'styled-components';
import TemplateContextProvider from './context/Provider';
import style from './style';
import { View } from './View';
import { useTemplatesSetup } from '../../api/query-hooks/masterData';

const Styled = styled(Layout.Column)`
  ${style}
`;

export const TemplatesEditor = () => {
  const templatesSetup = useTemplatesSetup();

  return (
    <Styled className={cn('templates-editor')}>
      <Core.Spinner spin={templatesSetup.isLoading}>
        <TemplateContextProvider initialValues={templatesSetup?.data}>
          <View />
        </TemplateContextProvider>
      </Core.Spinner>
    </Styled>
  );
};
