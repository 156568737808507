import cn from 'classnames';
import { Core, Form, Layout } from 'connex-cds';
import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { entities, profiles } from '../../api';
import { useListAndDetailContext } from '../../for_sdk/list-and-detail/ListAndDetailProvider';

const Styled = styled(Layout.Column)`
  margin-top: 30px;
  .buttons {
    margin-top: 20px;
    display: flex;
    .left {
      flex: 1;
    }
  }
`;

const optionsMap = r => {
  return { id: r.crn, label: `${r.lastName}, ${r.firstName} (${r.email})` };
};

const getInterimOptions = values => [
  {
    firstName: values?.firstName,
    lastName: values?.lastName,
    createDate: values?.createDate,
    modifyDate: values?.modifyDate,
    email: values?.email,
    crn: values?.toRef,
  },
];

export const ConnectionEditor = ({ updateFn, createFn, deleteFn, onFinally }) => {
  const { context, rows } = useListAndDetailContext();
  const { Components, setFieldValue, ...formContext } = Form.useFormContext();
  const [allProfiles, setAllProfiles] = React.useState(null);

  const [options, setOptions] = React.useState(getInterimOptions(formContext?.values));

  const [busy, setBusy] = React.useState(false);

  const _options = React.useMemo(async () => {
    const allProfiles = await profiles.listProfiles();

    setAllProfiles(allProfiles);

    const alreadyConnectedRefs = rows.map(profile => profile.toRef);

    return allProfiles.filter(profile => !alreadyConnectedRefs.includes(profile.crn));
  }, [rows]);

  const value = React.useMemo(() => {
    return formContext?.values?.toRef || undefined;
  }, [formContext?.values?.toRef]);

  const ensureOptionsContainCurrent = React.useCallback(
    (options = []) => {
      if (value) {
        const targetProfileRef = formContext?.values?.toRef;
        const targetProfile = find(allProfiles, { crn: targetProfileRef });

        if (!targetProfile) {
          return options || [];
        }
        const isValueAlreadyInList = !!find(options, { crn: targetProfileRef });
        return isValueAlreadyInList ? options : options.concat(targetProfile);
      }

      return options;
    },
    [allProfiles, formContext?.values?.toRef, value]
  );

  React.useEffect(() => {
    if (_options instanceof Promise) {
      setBusy(true);
      _options
        .then(response => {
          setOptions(ensureOptionsContainCurrent(response));
        })
        .finally(() => {
          setBusy(false);
        });
    } else {
      setOptions(_options || []);
    }
  }, [_options, ensureOptionsContainCurrent]);

  const handleChange = React.useCallback(
    profileRef => {
      const targetProfile = find(allProfiles, { crn: profileRef });
      setFieldValue('firstName', targetProfile?.firstName);
      setFieldValue('lastName', targetProfile?.lastName);
      setFieldValue('lastName', targetProfile?.lastName);
      setFieldValue('email', targetProfile?.email);
      setFieldValue('fromRef', context?.crn);
      setFieldValue('toRef', targetProfile.crn);
    },
    [allProfiles, context?.crn, setFieldValue]
  );

  const transformedOptions = React.useMemo(() => {
    if (options?.length) {
      return options.map(optionsMap);
    }
    return options || [];
  }, [options]);

  const handleCreate = React.useCallback(
    connection =>
      createFn?.(connection)?.then(response => {
        onFinally?.();
        return response;
      }),
    [createFn, onFinally]
  );

  const handleUpdate = React.useCallback(
    connection =>
      updateFn?.(connection)?.then(response => {
        onFinally?.();
        return response;
      }),
    [onFinally, updateFn]
  );

  const handleDelete = React.useCallback(
    connectionRef =>
      deleteFn?.(connectionRef)?.then(response => {
        onFinally?.();
        return response;
      }),
    [deleteFn, onFinally]
  );

  return (
    <Styled className={cn('connection-editor')}>
      <Core.Select
        name="connection"
        id="connection"
        onChange={handleChange}
        value={value}
        options={transformedOptions || []}
        testId="connection-form-field"
      />
      <div className="buttons">
        <div className="left">
          <Components.DeleteButton onDelete={handleDelete} />
        </div>
        <div className="right">
          <Components.SaveButton onCreate={handleCreate} onUpdate={handleUpdate} />
        </div>
      </div>
    </Styled>
  );
};
