import { Form } from 'connex-cds';
import { omit } from 'lodash';
import React from 'react';

export const useListConfig = ({ initialFetch, config }) => {
  const [rows, setRows] = React.useState(null);
  const [busy, setBusy] = React.useState(null);


  React.useEffect(() => {
    console.log('ue initialFetch');
    if (initialFetch?.fn) {
      setBusy(true);
      initialFetch
        .fn(initialFetch.args)
        .then(response => {
          setRows(response);
        })
        .finally(() => {
          setBusy(false);
        });
    } else {
      setBusy(false);
    }
  }, [initialFetch]);

  const formConfig = React.useMemo(() => {
    // We're leveraging the DynamicForm stuff to generate the grid and other components, but the
    // incoming config is a simplified version of what's required by the FormProvider.  This transforms
    // that incoming config to meet those requirements.
    return {
      fields: [
        {
          ...omit(config, 'fields'),
          dataType: Form.FIELD_TYPES.array,
          arrayOf: {
            fields: config.fields,
          },
        },
      ],
    };
  }, [config]);

  return {
    busy,
    formConfig,
    rows,
  };
};
