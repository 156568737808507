import { Form } from 'connex-cds';
import React from 'react';
import { entities } from '../../api/query-hooks';
import { CompanyEditor } from './CompanyEditor';
import config from './entityConfig';

const { DETAIL_TYPES, ListAndDetail } = Form;

export const Companies = () => {
  const listQuery = entities.useListEntities();
  const updateQuery = entities.useUpdateEntity();
  const createQuery = entities.useCreateEntity();

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.VIEW}
      Editor={CompanyEditor}
      config={config}
      listQuery={listQuery}
      updateQuery={updateQuery}
      createQuery={createQuery}
    />
  );
};
