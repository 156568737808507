import { Form } from 'connex-cds';
import React from 'react';
import { apps, connections, profiles } from '../../../api';
import { DETAIL_TYPES, ListAndDetail } from '../../../for_sdk/list-and-detail/ListAndDetail';
import config from '../ProfileConnectionConfig';
import { ProfileConnectionEditor } from '../ProfileConnectionEditor';

const onDelete = connectionRef => connections.deleteConnection({ connectionRef });

const onCreate = val => apps.createProfileAppConnection({ profileRef: val?.fromRef, appRef: val?.toRef });

const onUpdate = val => console.log('onUpdate', val);

export const ProfileApps = () => {
  const { values } = Form.useFormContext();

  console.log('values', values);

  const initialFetch = React.useMemo(() => {
    return { fn: profiles.listConnections, args: { profileRef: values?.crn, type: 'app-profile' } };
  }, [values?.crn]);

  return (
    <ListAndDetail
      detailType={DETAIL_TYPES.DRAWER}
      Editor={ProfileConnectionEditor}
      config={config}
      initialFetch={initialFetch}
      context={values}
      id="Users"
      updateFn={onUpdate}
      createFn={onCreate}
      deleteFn={onDelete}
    />
  );
};
