import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem } from 'connex-cds';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { InitializeApi } from './api/InitializeApi';
import { AppRoutes } from './AppRoutes';
import messages from './i18n';
import menuConfig from './menu-config';
import { Apps } from './views/apps/Apps';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { Companies } from './views/companies/Companies';
import { Landing } from './views/Landing';
import { Profiles } from './views/profiles/Profiles';
import { TemplatesEditor } from './views/templates/TemplatesEditor';

API.setSource('support');

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 } },
});

const App = () => {
  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem
            messages={messages}
            initialTimeZone="America/Chicago"
            appId="support"
            menuConfig={menuConfig}
          >
            <AppRoutes />
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;
