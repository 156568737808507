import { Form } from 'connex-cds';
import { find, isEmpty } from 'lodash';
import { FILTER_STRATEGY } from '../../for_sdk/filter-strategies';
import { PermissionsFieldEditor } from './permissions-field-editor/PermissionsFieldEditor';

const appTypes = [
  { id: 'CAI', label: 'CAI' }, // Stand-alone app.
  { id: 'SERVICE', label: 'Service' }, // Stand-alone service.
  { id: 'CX_APP_CAI', label: 'Connex App (CAI)' }, // Iframed into CONNEX
  { id: 'CX_APP_CORE', label: 'Connex App (Core)' }, // Built into CONNEX
];

export default {
  path: 'apps',
  labelStringId: 'apps',
  testId: 'apps',
  keyPath: 'crn',
  fields: [
    {
      path: 'crn',
      labelStringId: 'id',
      testId: 'crn',
      dataType: Form.FIELD_TYPES.string,
      allowedModes: Form.MODES.CREATE,
    },
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: Form.FIELD_TYPES.string },
    {
      path: 'type',
      labelStringId: 'type',
      testId: 'type',
      dataType: Form.FIELD_TYPES.string,
      listValues: appTypes,
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'CAI',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      sortKey: 'type',
      formatter: props => {
        const target = find(appTypes, { id: props.row.type });
        return target?.label || '';
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            {appTypes.map(type => (
              <option value={type.id}>{type.label}</option>
            ))}
          </select>
        );
      },
    },
    { path: 'apiKey', labelStringId: 'apiKey', testId: 'apiKey', dataType: Form.FIELD_TYPES.string },
    { path: 'port', labelStringId: 'localHostPort', testId: 'port', dataType: Form.FIELD_TYPES.number },
    {
      path: 'isAssignableToProfile',
      labelStringId: 'isAssignableToProfile',
      testId: 'isAssignableToProfile',
      dataType: Form.FIELD_TYPES.boolean,
      formatter: props => (props.row.isAssignableToProfile ? 'Yes' : 'No'),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'isEntitySpecific',
      labelStringId: 'isEntitySpecific',
      testId: 'isEntitySpecific',
      dataType: Form.FIELD_TYPES.boolean,
      formatter: props => (props.row.isEntitySpecific ? 'Yes' : 'No'),
      filterFn: (item, filterStrings) => {
        if (filterStrings[0] === 'Yes') {
          return item === true;
        } else if (filterStrings[0] === 'No') {
          return item === false;
        }
        return true;
      },
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );
      },
    },
    {
      path: 'permissions',
      name: 'permissions',
      labelStringId: 'permissions',
      dataType: Form.FIELD_TYPES.array,
      testId: 'permissions',
      multiline: true,
      Component: PermissionsFieldEditor,
      formatter: props => {
        if (Array.isArray(props?.row?.permissions)) {
          return props?.row?.permissions?.map?.(p => p.id)?.join?.(', ') || '';
        }
        return Object.keys(props?.row?.permissions || {})?.join?.(', ') || '';
      },
    },
    {
      path: 'status',
      labelStringId: 'status',
      testId: 'status',
      dataType: Form.FIELD_TYPES.string,
      listValues: [
        { id: 'ACTIVE', label: 'Active' },
        { id: 'INACTIVE', label: 'Inactive' },
      ],
      listValuePath: 'id',
      listDisplayPath: 'label',
      defaultValue: 'ACTIVE',
      filterStrategy: FILTER_STRATEGY.EQUALS,
      filterComponent: ({ innerRef, ...props }) => {
        return (
          <select ref={innerRef} {...props}>
            <option value="" />
            <option value="ACTIVE">Active</option>
            <option value="INACTIVE">Inactive</option>
          </select>
        );
      },
    },
  ],
};
